import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PersonCard from "./PersonCard";
import PersonCruises from "./PersonCruises";
import NotFoundError from "../common/NotFoundError";
import LoadingError from "../common/LoadingError";
import people from "../../apis/people";
import ranking from "../../apis/ranking";
import cruisesApi from "../../apis/cruises";
import RankingChart from "../common/ApexRankingChart";
import { useParams } from "react-router";

const PersonDetailsPage = () => {

  const { personId } = useParams();
  const [person, setPerson] = useState({ id: 0, firstName: "", lastName: "", totalMiles: 0 });
  const [cruises, setCruises] = useState([]);
  const [firstYear, setFirstYear] = useState(0);
  const [personLoading, setPersonLoading] = useState(false);
  const [personError, setPersonError] = useState(false);
  const [personErrorMessage, setPersonErrorMessage] = useState("");
  const [cruisesLoading, setCruisesLoading] = useState(false);
  const [cruisesError, setCruisesError] = useState(false);
  const [cruisesErrorMessage, setCruisesErrorMessage] = useState("");
  const [ranksData, setRanksData] = useState([]);
  const [personStats, setPersonStats] = useState({});

  const onPersonDetailsLoaded = (person) => {
    setPerson(person);
  };

  const onCruisesLoaded = (cruises) => {
    setCruises(cruises);
    setFirstYear(cruises
      .map((cruise) => cruise.startDate.substring(0, 4))
      .reduce((a, b) => (a > b ? b : a), 99999));
  };

  const onPersonCruisesLoadError = (error) => {
    let errorMessage = (
      <LoadingError
        message={
          <FormattedMessage
            id="error.loading.cruises"
            defaultMessage="Whoops! Something went wrong :("
          />
        }
      />
    );
    setCruisesError(true);
    setCruisesErrorMessage(errorMessage);
  };

  const getTopPosition = ranksData => {
    const ranks = ranksData[0]?.cruiseRanks || [];
    return Math.min(...ranks.map(rank => rank.position - 1));
  }

  useEffect(() => {

    const onPersonDetailsLoadError = (error) => {
      let errorMessage = "";
      if (error.response && error.response.status === 404) {
        errorMessage = (
          <NotFoundError
            message={
              <FormattedMessage
                id="error.notFound.person"
                defaultMessage={`Cannot find person ${personId}`}
              />
            }
          />
        );
      } else {
        errorMessage = (
          <LoadingError
            message={
              <FormattedMessage
                id="error.loading.person"
                defaultMessage="Whoops! Something went wrong :("
              />
            }
          />
        );
      }
      setPersonError(true);
      setPersonErrorMessage(errorMessage);
    };

    const loadPersonDetails = (personId) => {
      setPersonLoading(true);
      people.get(personId)
        .then((response) => onPersonDetailsLoaded(response.data))
        .catch((error) => onPersonDetailsLoadError(error))
        .finally(() => setPersonLoading(false));
    };

    const loadPersonCruises = (personId) => {
      setCruisesLoading(true);
      cruisesApi
        .getPersonCruises(personId)
        .then((response) => onCruisesLoaded(response.data.cruises))
        .catch((error) => onPersonCruisesLoadError(error))
        .finally(() => setCruisesLoading(false));
    };
  
    const loadPersonRanks = (personId) => {
      //setRanksLoading(true);
      ranking.getRanks(personId)
        .then(({data}) => setRanksData(data.peopleRanks))
        .catch(error => console.error('Ranks loading error', error))
        //.finally(() => setRanksLoading(false));
    };

    const loadPersonStats = (personId) => {
      people.getStats(personId)
        .then(({data}) => setPersonStats(data))
        .catch(error => console.error('Stats loading error', error))
    };

    loadPersonDetails(personId);
    loadPersonCruises(personId);
    loadPersonRanks(personId);
    loadPersonStats(personId);
  }, [personId])

  const showChart = true//ranksData[0]?.cruiseRanks?.length > 1;

  return (
    <div>
      {personError ? (
        personErrorMessage
      ) : (
        <PersonCard
          person={person}
          firstYear={firstYear}
          loading={personLoading}
          stats={{...personStats, topPosition: getTopPosition(ranksData)}}
        />
      )}

      
      {showChart 
        ? <div className="ui segment">
            <RankingChart data={ranksData} />
          </div>
        : null}

      {cruisesError ? (
        cruisesErrorMessage
      ) : (
        <PersonCruises
          cruises={cruises}
          loading={cruisesLoading}
        />
      )}
    </div>
  );
}

export default PersonDetailsPage;
