import milesrank from './milesrank';

const RankingMethods = {

  getGeneralRankingPage:  (pageNumber, pageSize, query) => {
      return milesrank.get("/api/rankings/general", {
      params: {
        pageNo: pageNumber,
        pageSize: pageSize,
        query: query === undefined ? "" : query
      }
    });
  },

  getCruiseRankingPage: (pageNumber, pageSize, cruiseId, query) => {
    return milesrank.get("/api/rankings/cruise/" + cruiseId, {
      params: {
        pageNo: pageNumber,
        pageSize: pageSize,
        query: query === undefined ? "" : query
      },
    });
  },

  getYearRankingPage: (pageNumber, pageSize, year, query) => {
    return milesrank.get("/api/rankings/year/" + year, {
      params: {
        pageNo: pageNumber,
        pageSize: pageSize,
        query: query === undefined ? "" : query
      },
    });
  },

  getYachtCrewRankingPage: (pageNumber, pageSize, yachtId, query) => {
    return milesrank.get("/api/rankings/yacht/" + yachtId, {
      params: {
        pageNo: pageNumber,
        pageSize: pageSize,
        query: query === undefined ? "" : query
      },
    });
  },

  getRanks: (personId) => {
    return milesrank.get(`/api/rankings/${personId}/positions`);
  },

  getMultiRanks: (personIds) => {
    if (personIds !== undefined && personIds.length > 0)
      return milesrank.get(`/api/rankings/${personIds.join(',')}/positions`);
    return Promise.resolve();
  },

  getTopRanks: (count) => {
    return milesrank.get("/api/rankings/simple/top/" + count);
  },

}

export default RankingMethods;