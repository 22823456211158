import React, { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  NavLink,
  Link
} from "react-router-dom";
import { Menu, Sidebar } from "semantic-ui-react";
import { hasRole } from "../../core/Auth";
import AppRoutes from "./AppRoutes";

const MobileLayout = ({ onLogout, isAuthenticated  }) => {

  const [sidebarVisible, setSidebarVisible] = useState(false);

  const logoutClicked = e => {
    e.preventDefault();
    onLogout();
  }

  return (

    <div>

      <Sidebar.Pushable>
          <Sidebar 
            id="milesrank-main-menu"
            as={Menu}
            inverted
            vertical
            animation='overlay'
            onHide={() => setSidebarVisible(false)}
            onClick={() => setSidebarVisible(false)}
            visible={sidebarVisible}
          >
            <div className="header item">
              <Link to="/" className="logo-link">
                <img className="kanon-logo" src="/logo-kst-orange.svg" height="70" alt="KanonSailing.pl" />
              </Link>
            </div>
            <Link to="/" className="item">
              <FormattedMessage id="menu.ranking.general" defaultMessage="General Ranking" />
            </Link>
            <Link to="/?year=2023" className="item">
              <FormattedMessage id="menu.ranking.yearly" defaultMessage="Yearly Ranking" />
            </Link>
            <NavLink to="/cruises" className="item">
              <FormattedMessage id="menu.cruises" defaultMessage="Cruises" />
            </NavLink>
            <NavLink to="/yachts" className="item">
              <FormattedMessage id="menu.yachts" defaultMessage="Yachts" />
            </NavLink>
            {hasRole('ADMIN') ? (
                <NavLink to="/users" className="item">
                  <FormattedMessage id="menu.users" defaultMessage="Users" />
                </NavLink>
             ) : null}

            {isAuthenticated ? (
              <Fragment>
                <Link to="/logout" className="item" style={{marginTop: 3 + 'em'}} onClick={logoutClicked}>
                  <i className="sign-out icon"></i>
                  <FormattedMessage id="action.logout" defaultMessage="Log out" />
                </Link>
              </Fragment>
            ) : null}

          </Sidebar>

          <Sidebar.Pusher>

            <div className="ui inverted menu">
              <div className="header item">
                <Link to="/" className="logo-link">
                  <img className="kanon-logo" src="/logo-kst-orange.svg" height="32" alt="KanonSailing.pl" />
                </Link>
              </div>
              <div className="floated right item" onClick={() => setSidebarVisible(true)}>
                <i className="ui big icon bars"></i>
              </div>
            </div>

            <div className="ui container">
              <AppRoutes />
            </div>

          </Sidebar.Pusher>
      </Sidebar.Pushable>

    </div>
);

};

export default MobileLayout;